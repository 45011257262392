// src/controllers/questions_controller.js
import { Controller } from "stimulus"
import tinymce from 'tinymce/tinymce'

// Import the default theme and plugins explicitly
// import 'tinymce/themes/default'
import 'tinymce/plugins/lists'
import 'tinymce/plugins/link'
import 'tinymce/plugins/image'
import 'tinymce/plugins/table'
import 'tinymce/plugins/code'

export default class extends Controller {
  static values = { formDirty: Boolean, formSubmitting: Boolean }

  connect() {
    this.tinyInit()

    this.dirtyFormConfirmation = this.dirtyFormConfirmation.bind(this)
    document.addEventListener("turbo:before-visit", this.dirtyFormConfirmation);

    $("#questionForm, #answer_option_form, #data_field_form").each(function(index, value) {
      let self = this
      $(value).find('input, select').each(function() {
        $(value).change(function() {
          self.unsavedChanges();
        });
      })
      $(value).on('submit', function() {
        self.setFormSubmitting
      })

      $(value).validate({
        errorPlacement: function(error,element) {
          return true;
        },
        highlight: function(element, errorClass, validator) {
          $(element).addClass(errorClass);
          $(element).closest("tr")
            .addClass(errorClass);
          self.setFormSubmitting(false);
        },
        unhighlight: function(element, errorClass, validator) {
          $(element).removeClass(errorClass);
          $(element).closest("tr")
            .removeClass(errorClass);
          self.setFormSubmitting(false);
        },
        showErrors: function (errorMap, errorList) {
          var errors = this.numberOfInvalids();
          var message = ''
          if (errors) {
            if (errors == 1) {
              message = 'You missed 1 field. It has been highlighted';
            } else {
              message = 'You have missed ' + errors + ' fields. Please fill before submitted.';
            }
            $(".question__error").html(message);
            $(".question__error").show();
          } else {
            $(".question__error").hide();
          }
          // ensures that highlight/unhighlight will function
          this.defaultShowErrors();
        },
        submitHandler: function() {
          self.setFormSubmitting();
          this.currentForm.submit()
        }
      });
    }.bind(this))

    $('#editMultipleAOModal').on('hidden.bs.modal', function () {
      $('#edit_mult_inputAO')[0].value = ""
    })

    $('#editMultipleDFModal').on('hidden.bs.modal', function () {
      $('#edit_mult_inputDF')[0].value = ""
    })
  }

  disconnect() {
    // this.dirtyFormConfirmation = this.dirtyFormConfirmation.bind(this)
    document.removeEventListener('turbo:before-visit', this.dirtyFormConfirmation);
    tinymce.remove("textarea#question-text")
  }

  questionValidatorMessage(errors) {
    $("#errorExplanation").hide();
    var message = '';
    if (errors) {
      if (errors == 1) {
        message = 'You missed 1 field. It has been highlighted'
      } else {
        message = 'You missed ' + errors + ' fields. They have been highlighted';
      }
      $(".question__error").html(message);
      $(".question__error").show();
    } else {
      $(".question__error").hide();
    }
  }

  unsavedChanges() {
    $('#unsaved-changes').show();
    this.formDirtyValue = true;
  }

  setFormSubmitting(submitting) {
    if (submitting == false) {
      this.formSubmitting = false;
    } else {
      this.formSubmitting = true;
    }
  };

  dirtyFormConfirmation(event) {
    if (this.formSubmittingValue || !this.formDirtyValue) {
      return undefined;
    }

    var confirmationMessage = 'You have unsaved changes. '
        + 'If you leave before saving, your changes will be lost.';

    if (!confirm(confirmationMessage)) {
      event.preventDefault();
    }
  }

  handleFileUpload(blobInfo, success, failure, progress) {
    var xhr, formData;

    xhr = new XMLHttpRequest();
    xhr.withCredentials = false;
    var token = document.querySelector("meta[name=csrf-token]").content;
    xhr.open("POST", "/uploads");
    xhr.setRequestHeader("X-CSRF-Token", token);

    xhr.upload.onprogress = function (e) {
      progress(e.loaded / e.total * 100);
    };

    xhr.onload = function() {
      var json;

      if (xhr.status < 200 || xhr.status >= 300) {
        failure("HTTP Error: " + xhr.status);
        return;
      }

      json = JSON.parse(xhr.responseText);

      if (!json || typeof json.location != "string") {
        failure("Invalid JSON: " + xhr.responseText);
        return;
      }

      success(json.location);
    };

    xhr.onerror = function () {
      failure("Image upload failed due to a XHR Transport error. Code: " + xhr.status);
    };

    formData = new FormData();
    formData.append("file", blobInfo.blob(), blobInfo.filename());

    xhr.send(formData);
    this.unsavedChanges();
  }

  tinyInit() {
    var self = this

    tinymce.init({
      verify_html : false,
      cleanup : false,
      height : 300,
      selector: "textarea#question-text",
      // plugins: [
      //   "advlist autolink lists link image charmap print preview anchor",
      //   "searchreplace visualblocks code fullscreen",
      //   "insertdatetime media table contextmenu paste"
      // ],
      plugins: [
        "lists", "link", "image", "table", "code", "searchreplace", "fullscreen"
      ],
      relative_urls : false,
      remove_script_host : false,
      convert_urls : true,
      toolbar: "insertfile undo redo | styleselect | bold italic | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | link image | code | fullscreen",
      promotion: false,
      valid_children : "+body[style]",
      images_upload_url: "/uploads",
      image_dimensions: false,
      image_class_list: [
        {title: "Inline Question Image Style", value: "question-img"}
      ],
      automatic_uploads: true,
      images_upload_handler: this.handleFileUpload.bind(this),
      setup(editor) {
        editor.on('blur', function() {
          if (editor.isDirty()) {
            self.unsavedChanges()
          }
        });
      }
    })
  }

  findNextPrecode(className) {
    let existingCodes = []
    $(className).each(function(){
      if ($(this).is(':visible')) {
        var precode = parseInt($(this).attr('data-precode'));
        existingCodes.push(precode);
      }
    })

    // ensure existing_codes has value
    existingCodes.push(0)
    const filteredCodes = existingCodes.filter(code => $.isNumeric(code))
    const nextIndex = Math.max.apply(Math, filteredCodes) + 1
    return nextIndex
  }

  // edit multiple answer_options and data_fields
  editMultiple() {
    const type = event.params.fieldtype
    const id = type === 'answer_option' ? 'edit_mult_inputAO' : 'edit_mult_inputDF'
    const className = type === 'answer_option' ? 'answer_options_attributes' : 'data_fields_attributes'
    const buttonName = type === 'answer_option' ? ".add_ans_fields" : ".add_dat_fields"
    const inputText = document.getElementById(id).value
    const labels = inputText.split("\n")
    const formId = type === 'answer_option' ? 'answer_option_form' : 'data_field_form'
    const form = document.getElementById(formId)

    for (let i = 0; i < labels.length; i++) {
      let split = labels[i].split("\t");
      const precode = split.length < 2 ? this.findNextPrecode(`input[name^='question[${className}]'][name*=precode]`) : split[1]
      const existing_field = $( `input[name^='question[${className}]'][name*='[precode]'][data-precode='${precode}']`).eq(0)

      // HONESTLY I DON'T KNOW WHY I NEED THIS AND I DON'T HAVE TIME TO FIGURE IT OUT, SO SORRY
      // - LEAH 1/11/24
      this.sleep(1);

      if ( existing_field.length > 0) {
        $( `input[name^='question[${className}]'][name*='[label]'][data-id='${existing_field.data('id')}']` ).val(split[0]);
      } else {
        let time = new Date().getTime()
        let regexp = new RegExp($(buttonName).data('id'), 'g')
        if (type == 'answer_option'){
          $('#answer_option_table tr:last').after($(buttonName).data('fields').replace(regexp, time))
        } else {
          $('#data_field_table tr:last').after($(buttonName).data('fields').replace(regexp, time))
        }
        $(buttonName).attr("prev_time", time)
        event.preventDefault()

        const pretime = $(buttonName).attr("prev_time");
        const label_input = $(`#question_${className}_${pretime}_label`);
        label_input.val(split[0]);
        label_input.attr('data-id', pretime);

        const precode_input = $(`#question_${className}_${pretime}_precode`);
        precode_input.val(precode);
        precode_input.attr('data-id', pretime);
        precode_input.attr('data-precode', precode)
      }
    }

    $(".close-reveal-modal").click();
  }

  sleep(milliseconds) {
    const date = Date.now();
    let currentDate = null;
    do {
      currentDate = Date.now();
    } while (currentDate - date < milliseconds);
  }

  addFieldWithPrecode() {
    const type = event.params.fieldtype
    const className = type === 'answer_option' ? 'answer_options_attributes' : 'data_fields_attributes'
    const buttonName = type === 'answer_option' ? ".add_ans_fields" : ".add_dat_fields"
    const nextIndex = this.findNextPrecode(`input[name^='question[${className}]'][name*=precode]`)

    $(buttonName).click();
    const pretime = $(buttonName).attr("prev_time");
    const precode_input = $(`#question_${className}_${pretime}_precode`);
    precode_input.val(nextIndex);
    precode_input.attr('data-id', pretime);
    precode_input.attr('data-precode', nextIndex);

    this.unsavedChanges();
  }

  deleteDataField(event, datafield) {
    var existing_label, existing_precode, id;

    let target = datafield || event.currentTarget
    $(target).prev('input[type=hidden]').val('true');

    id = $(target).prev('input[type=hidden]').attr('data-id');
    existing_precode = $("input[name^='question[data_fields_attributes]'][name*='[precode]'][data-id='" + id + "']").eq(0);
    existing_precode.attr('data-id', "");
    existing_precode.attr('data-precode', "");
    existing_precode.val("");
    existing_label = $("input[name^='question[data_fields_attributes]'][name*='[label]'][data-id='" + id + "']").eq(0);
    existing_label.attr('data-id', "");
    $(target).closest('tr').hide();
    event.preventDefault();
    this.unsavedChanges();
  }

  deleteSelectedDataFields() {
    $("input[name='ids[]']:checked").each(function(index, value) {
      let link_ref = $(value).closest('tr').find('a.remove_fields')
      this.deleteDataField(event, link_ref)
    }.bind(this));

    $('#select-all-ids').prop("checked", false);
  }

  // TODO: Merge this into deleteField and deleteSelectedFields
  deleteAnswerOption(event, datafield) {
    var existing_label, existing_precode, id;

    let target = datafield || event.currentTarget
    $(target).prev('input[type=hidden]').val('true');

    id = $(target).prev('input[type=hidden]').attr('data-id');
    existing_precode = $("input[name^='question[answer_options_attributes]'][name*='[precode]'][data-id='" + id + "']").eq(0);
    existing_precode.attr('data-id', "");
    existing_precode.attr('data-precode', "");
    existing_precode.val("");
    existing_label = $("input[name^='question[answer_options_attributes]'][name*='[label]'][data-id='" + id + "']").eq(0);
    existing_label.attr('data-id', "");
    $(target).closest('tr').hide();
    event.preventDefault();
    this.unsavedChanges();
  }

  deleteSelectedAnswerOptions() {
    $("input[name='answer_ids[]']:checked").each(function(index, value) {
      let link_ref = $(value).closest('tr').find('a.remove_fields')
      this.deleteAnswerOption(event, link_ref)
    }.bind(this));

    $('#select-all-ids').prop("checked", false);
  }
}
